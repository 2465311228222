<template>
  <div>
    <div
      class="intro-container row-24 m-0 items-center"
      :style="backgroundImgUrl ? {backgroundImage: `url(${backgroundImgUrl})`} : null"
      @click="scrollDown"
    >
      <h1 class="intro-title col-18 offset-3" v-html="payload.introtext" />
      <ScrollIndicator />
    </div>
    <section class="photo-section bg-white row-24 mx-0">
      <div
        ref="grid"
        class="persons-container grid col-22 offset-1
        md:offset-6 md:col-17 lg:col-19 lg:offset-4 p-0"
      >
        <div class="grid-sizer" />
        <Go
          v-for="person in randomizedPhotographers"
          :key="person.id"
          class="person-wrapper-link grid-item"
          :to="person.link"
        >
          <div
            class="person"
          >
            <h2 class="person-name" v-html="person.name" />
            <img :src="person.teaserbild.large" :alt="person.alt" class="person-teaser-image">
          </div>
        </Go>
      </div>
    </section>
  </div>
</template>

<script>

import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      photographers: this.payload.photographers,
      backgroundImgUrl: this.payload.introhintergrund,
    };
  },
  computed: {
    randomizedPhotographers() {
      const copiedPhotographers = [...this.photographers];
      return this.shuffleElements(copiedPhotographers);
    },
  },
  mounted() {
    imagesLoaded(this.$refs.grid, () => {
      this.iso = new Isotope(this.$refs.grid, {
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: 20,
        },
        itemSelector: '.grid-item',
      });
    });
  },
  unmounted() {
    setTimeout(() => this.iso.destroy(), 500);
  },
  methods: {
    shuffleElements(array) {
      let currentIndex = array.length; let
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/styles/_mixin.scss";
.intro-container {
  background-color: var(--color-white);
  :deep(.scroll-indicator) {
    svg {
      color: var(--color-black);
    }
  }
}
.intro-title {
  &:deep(p) {
    color: var(--color-black);
  }
}

.photo-section {
  padding: 4rem 0;
}

.person-wrapper-link {
  display: inline-block;
  &:nth-child(3n + 1) {
    height: 300px;
    @screen sm {
      height: 400px;
    }
    @screen md {
      height: 500px;
    }
    @screen lg {
      height: 800px;
    }
  }
  &:nth-child(3n + 2) {
    height: 300px;
    @screen sm {
      height: 475px;
      width: 40%;
    }
    @screen md {
      height: 550px;
      width: calc(50% - 10px);
    }
    @screen lg {
      height: 500px;
      width: 33%;
    }
  }
  &:nth-child(3n) {
    height: 300px;
    @screen sm {
      height: 350px;
      width: 33%;
    }
    @screen md {
      height: 450px;
      width: 33%;
    }
    @screen lg {
      height: 650px;
      width: 25%;
    }
  }
}

.person {
  position: relative;
  height: 100%;
  width: 100%;
  &-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    margin: 0;
    :deep(p) {
      color: var(--color-white);
      font-family: var(--font-primary);
      font-size: 2rem;
      line-height: 1.1;
      text-align: center;
      margin: 0;
      @screen lg {
        font-size: 3rem;
      }
    }
  }
  &-teaser-image {
    margin: 0;
    width: 100%;
    // height: auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

$spacing: 20px;

.grid {
  --cols: 1;
  @screen sm {
    --cols: 2;
  }
}

.grid-sizer,
.grid-item {
  width: calc((100% / var(--cols)) - (#{$spacing} / (var(--cols)) * (var(--cols) - 1)));
}

.grid-item {
  margin-bottom: $spacing;
}
</style>
